nb-layout-header {
  background-color: #000 !important;
  // background-image: url(https://png.pngtree.com/thumb_back/fh260/background/20221110/pngtree-three-kings-and-gifts-seamless-pattern-image_1421761.jpg);
  // background-image: url('https://firebasestorage.googleapis.com/v0/b/bookline-pro.appspot.com/o/pedro%40bookline.io%2Fimage%2Fpng%2F260-gracias-javi.png?alt=media');
  // background-image: url('../../../assets/images/summer3.jpeg');
  // background-image: url('../../../assets/images/merrychristmas.gif');
  background-size: 100px;
}

// nb-layout-header h1 {
//   background-color: rgba(114, 182, 188, 0.5);
// }

.nb-theme-corporate nb-user .user-name {
  color: white !important;
  font-weight: bold !important;
  margin-left: 0.5rem;
}

.nb-theme-corporate .header-container nb-action nb-icon {
  color: white !important;
}

.nb-theme-corporate .header-container .logo {
  border-left: 1px solid #383838 !important;
}

.nb-theme-corporate .header-container nb-action {
  border-left: 1px solid #383838 !important;
}

.chat-widget {
  height: 100vh !important;
  width: 50vw !important;
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
  right: 0;
  bottom: 0;
}
.chat-widget nb-card {
  height: 80vh !important;
  width: 50vw;
  position: absolute !important;
  bottom: 0px;
}
.chat-widget nb-card .header {
  display: none;
}

.chat-widget nb-card nb-card-header {
  display: none !important;
}
.chat-widget nb-card nb-card-body {
  padding: 0 !important;
}

.action {
  margin-bottom: 1em;
}

.action-tags {
  margin-bottom: 0.5em;
}

.action-content {
  padding: 15px;
  border-radius: 10px;
  background-color: #f2f2f2;
}

.actions {
  padding-top: 15px;
  padding-bottom: 15px;
}

.input {
  padding: 15px;
  font-weight: 700;
  background-color: #192038;
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
  border-radius: 7px;
}
