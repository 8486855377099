@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '~@nebular/theme/styles/globals';
@import '~@nebular/auth/styles/globals';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';
@import './custom';

@import '~@ng-select/ng-select/themes/material.theme.css';

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.ag-theme-alpine .ag-root-wrapper {
  border-radius: 10px;
}

.ag-theme-alpine .ag-cell {
  border-right: 1px solid #cccccc;
  white-space: normal !important;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
}

.ag-theme-alpine .ag-header-cell {
  border-right: 1px solid #cccccc;
}

.ag-ltr .ag-cell {
  border-right: 1px solid #cccccc !important;
  word-break: break-all !important;
}

.ag-grid-table-wrapper {
  border-radius: 12px;
  border: solid 1px rgb(194 192 192);
  background-color: white;
}
.ag-grid-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 12px 0px 12px;
}

.ag-grid-button-container-accounts {
  display: flex;
  justify-content: space-between;
  padding: 12px 12px 0px 12px;
}
.ag-grid-title-style {
  font-size: 18px;
  font-weight: bold;
}
.ag-grid-new-btn {
  background-color: #36f;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
}
.ag-grid-download-btn {
  margin-left: 8px;
  background-color: rgb(205, 201, 201);
  color: black;
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
}

.grid-features-btn:hover {
  background-color: rgb(78, 119, 242);
}

.ag-grid-table-container {
  padding: 12px;
}

.ag-cell-value {
  word-break: break-word !important;
}
